import React, { useState, useRef, useCallback } from 'react';
import { Box, Center, Flex, Image, Input, chakra, Spinner } from '@chakra-ui/react';

import Jin from './assets/jin.png';
import apis from './apis';

const Video = chakra('video');

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [vidUrl, setVidUrl] = useState<string | undefined>(undefined);

  const contentRef = useRef<HTMLInputElement | null>(null);
  const sendContent = useCallback(async () => {
    if (contentRef.current == null) {
      return;
    }

    const content = contentRef.current.value;
    contentRef.current.value = '';

    setIsLoading(true);
    const { url } = await apis.talk(content);
    setVidUrl(url);
    setIsLoading(false);
  }, []);

  const onVideoEnded: React.ReactEventHandler<HTMLVideoElement> = useCallback(() => {
    setVidUrl(undefined);
  }, []);

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.code !== 'Enter') {
        return;
      }

      if (!e.nativeEvent.isComposing) {
        e.preventDefault();
        sendContent();
      }
    },
    [sendContent],
  );

  return (
    <Flex flexDir="column" w="auto" h="100vh" gap="16px" alignItems="center" p="8px">
      <Box flex={1} pos="relative" w="fit-content" minH={0}>
        <Image src={Jin} alt="image" h="100%" />
        {vidUrl === undefined && isLoading && (
          <Center pos="absolute" left="0" top="0" w="100%" h="100%" bg="blackAlpha.500">
            <Spinner size="xl" color="white" />
          </Center>
        )}
        {vidUrl !== undefined && (
          <Video pos="absolute" left="0" top="0" h="100%" poster={Jin} autoPlay playsInline onEnded={onVideoEnded}>
            <source src={vidUrl} type="video/mp4" />
          </Video>
        )}
      </Box>

      <Box minH={0} w="100%">
        <Input
          flex={0}
          ref={contentRef}
          type="text"
          placeholder="Enter English"
          onKeyDown={onKeyDown}
          isDisabled={isLoading || vidUrl !== undefined}
        />
      </Box>
    </Flex>
  );
};

export default App;
