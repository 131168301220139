import axios from 'axios';

// import Vid1 from '../assets/vids/1.mp4';
// import Vid2 from '../assets/vids/2.mp4';
// import Vid3 from '../assets/vids/3.mp4';
// import Vid4 from '../assets/vids/4.mp4';
// import Vid5 from '../assets/vids/5.mp4';
// import Vid6 from '../assets/vids/6.mp4';

import type { TalkRequest, TalkResponse } from './dto';

// const baseURL = 'http://ec2-3-35-13-81.ap-northeast-2.compute.amazonaws.com:8080';
const baseURL = 'https://dev-th.twinit.ai';

function createApi() {
  const client = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // const vids = [Vid1, Vid2, Vid3, Vid4, Vid5, Vid6];
  // const vids = [Vid6];
  // let idx = 0;

  return {
    talk: async (content: string): Promise<TalkResponse> => {
      const payload: TalkRequest = { content };
      const resp = await client.post<TalkResponse>('/talk', JSON.stringify(payload));
      return resp.data;

      // const vid = vids[idx];
      // idx += 1;
      // idx %= vids.length;

      // const sec = 2 + Math.random() * 3;
      // await sleep(1000 * sec);
      // return {
      //   url: vid,
      // };
    },
  };
}

// async function sleep(ms: number): Promise<void> {
//   return new Promise<void>((resolve) => {
//     setTimeout(resolve, ms);
//   });
// }

export default createApi();
